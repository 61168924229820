/* Draw the eyeball diagram with all supporting information.
This class is agnostic to whichever side is being drawn. */

import i18n from '@/plugins/i18n';

export default class DiagramCanvas {
    // Maybe the constructor should take an object with all of the properties, rather than individual variables
    constructor(config, data) {
        this.canvas = config.canvas;
        this.context = config.context;
        this.bgImage = config.bgImage;
        this.side = config.side;
        this.eyeSize = 350;
        this.labelFont = '600 .733rem Proxima Nova';
        this.measurementFont = '1.027rem Proxima Nova';
        this.sideFont = '900 4.8rem Proxima Nova';

        this.bgImageObj = new Image(this.canvas.width, this.canvas.height);
        this.bgImageObj.onload = () => {
            // Adding this timeout because safari is not rendering image in canvas once onload image is ready
            setTimeout(() => this.drawDiagram(data), 500);
        }; // Do the initial diagram drawing when the page first loads.
        this.bgImageObj.src = this.bgImage;
    }

    drawDiagram(data) {
        this.context.globalAlpha = 1;
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        //Draw the svg files
        this.context.drawImage(this.bgImageObj, 0, 0, this.canvas.width, this.canvas.height);

        // Draw the text boxes
        const {wwX, wwY, acdX, acdY, cctX, cctY} = this.drawBoxes(data.validation, data.warnings);

        // Draw Eye side title
        this.context.fillStyle = '#c2c9d1';
        this.context.font = this.sideFont;
        this.context.fillText(this.side, 5, 60);

        // Draw the text
        this.context.fillStyle = 'white';

        // Redraw the measurement values in their proper locations
        this.context.font = `900 ${this.measurementFont}`;
        this.context.fillText(data.cct ? data.cct : '', cctX, cctY);
        this.context.fillText(data.ww ? data.ww : '', wwX, wwY);
        this.context.fillText(data.acd ? data.acd : '', acdX, acdY);

        // Redraw the measurement units in their proper locations
        this.context.font = `400 ${this.measurementFont}`;
        this.context.fillText(data.cct ? 'µm' : '', cctX + data.cct?.length * 10, cctY);
        this.context.fillText(data.ww ? 'mm' : '', wwX + (data.ww?.length - 1) * 10, wwY);
        this.context.fillText(data.acd ? ' mm' : '', acdX + (data.acd?.length - 1) * 10, acdY);

        // Order is important here, because the refraction axis needs to be drawn under the other axes
        this.drawAxis('refraction', data.refractionAxis);
        this.drawAxis('k1', data.k1Axis, data.k2Axis);
        this.drawAxis('k2', data.k2Axis, data.k1Axis);

        if (!data.activeEditing) {
            this.drawInactiveOverlay();
        }
    }

    drawAxis(axis, axisMeasurement, secondAxis = null) {
        if (!this.hasValue(axisMeasurement)) {
            return;
        }
        let color = 'white';
        let radius = this.eyeSize / 2 - 40;
        let offset = 5; //adjust the offset so it doesnt overwrite the angle on the other k axis
        let textColor = 'white';
        this.context.setLineDash([33, 3]);
        this.context.lineWidth = 5;
        // Choose the proper line color based on which axis we're drawing
        switch (axis) {
            case 'k2':
                color = '#0066CC';
                break;
            case 'k1':
                color = '#2D912C';
                break;
            case 'refraction':
                color = '#EBEBE9';
                radius = this.eyeSize / 2;
                offset += 5;
                textColor = 'black';
                this.context.lineWidth = 10;
                this.context.setLineDash([]);
        }

        if (
            axisMeasurement > 180 ||
            axisMeasurement < 0 ||
            (this.hasValue(secondAxis) &&
                Math.abs(Number(secondAxis) - Number(axisMeasurement)) != 90)
        ) {
            color = 'red';
            textColor = 'white';
        }

        // Draw the dashed line for the axis

        // In HTML5 canvas, the cartesian coordinate layout is upside-down/inverted, so multiply the provided angle by -1 to correct it
        let angle = -1 * axisMeasurement;
        let rads = angle * (Math.PI / 180);
        let centerX = this.canvas.width / 2 - 50;
        let centerY = this.canvas.height / 2;

        let x1 = centerX - Math.cos(rads) * (radius + offset);
        let y1 = centerY - Math.sin(rads) * (radius + offset);
        let x2 = centerX + Math.cos(rads) * radius;
        let y2 = centerY + Math.sin(rads) * radius;

        this.context.strokeStyle = color;
        let rectWidth = 20;
        let rectHeight = 40;
        let rectX = x2;
        let rectY = y2 - rectHeight / 2;

        this.context.beginPath();

        this.context.moveTo(x2, y2);
        this.context.lineTo(x1, y1);
        this.context.stroke();
        this.context.closePath();
        //Draw rectangle at the end of the dashed line
        this.context.beginPath();

        this.context.save();

        this.context.translate(rectX, rectY + rectHeight / 2);
        this.context.rotate((Math.PI / 180) * angle);
        this.context.translate(-rectX, -(rectY + rectHeight / 2));
        this.context.setLineDash([]);
        this.context.fillStyle = color;
        this.context.fillRect(rectX, rectY, rectWidth, rectHeight);
        this.context.restore();

        // Draw the angle measurement text on the rectangle for the axis
        this.context.save();
        this.context.translate(rectX, rectY + rectHeight / 2);
        this.context.rotate((Math.PI / 180) * (90 + angle));
        this.context.translate(-rectX, -(rectY + rectHeight / 2));

        this.context.fillStyle = textColor;
        this.context.textAlign = 'center';
        this.context.textBaseline = 'middle';
        this.context.fillText(`${Math.abs(angle)}\xB0`, rectX, rectY + 10);

        this.context.stroke();

        this.context.restore();

        this.context.closePath();
    }

    //split the supplied text on linebreak
    //into an array and return at least 3 entries
    splitText(text) {
        let result = text.split(/\r?\n/);
        while (result.length < 3) {
            result.push('');
        }
        return result;
    }

    drawBoxes(validation, warnings) {
        const isInvalid = (key) => {
            return (
                validation.dataSet[key].$model &&
                validation.dataSet[key].$dirty &&
                validation.dataSet[key].$error
            );
        };

        let boxScaleX = 0.087;
        let boxScaleY = 0.16;

        let canvasWidth = this.canvas.width;
        let canvasHeight = this.canvas.height;

        let boxWidth = canvasWidth * boxScaleX;
        let boxHeight = canvasHeight * boxScaleY;

        let labelOffsetX = boxWidth * 0.0056 + 4;
        let labelOffsetY = boxHeight * 0.2;

        let strokeColor = '#787878';
        let fillColor = '#202020';
        let errorStrokeColor = '#C40F20';
        let errorFillColor = '#C40F20';
        let warningColor = '#f3aa1c';

        this.context.strokeStyle = '#787878';
        this.context.fillStyle = '#202020';
        this.context.lineWidth = 1;
        this.context.setLineDash([]);
        this.context.beginPath();

        // ww box:
        let wwX = canvasWidth * 0.1;
        let wwY = canvasHeight * 0.66;
        let wwX1 = canvasWidth * 0.15;
        let wwY1 = canvasHeight * 0.82;
        let wwX2 = canvasWidth * 0.266;
        let wwY2 = wwY1;
        this.context.strokeStyle = isInvalid('ww') ? errorStrokeColor : strokeColor;
        this.context.fillStyle = isInvalid('ww') ? errorFillColor : fillColor;
        this.context.rect(wwX, wwY, boxWidth, boxHeight);
        this.context.fill();
        this.context.stroke();

        this.context.lineWidth = 2;
        this.context.moveTo(wwX1, wwY1);
        this.context.lineTo(wwX2, wwY2);
        this.context.stroke();

        // acd box:
        this.context.beginPath();
        let acdX = canvasWidth * 0.84;
        let acdY = canvasHeight * 0.8;
        this.context.strokeStyle = strokeColor;
        this.context.fillStyle = fillColor;
        if (isInvalid('acd')) {
            this.context.strokeStyle = errorStrokeColor;
            this.context.fillStyle = errorFillColor;
        }

        this.context.lineWidth = 1;
        this.context.rect(acdX, acdY, boxWidth, boxHeight);
        this.context.fill();
        this.context.stroke();

        let acdX1 = canvasWidth * 0.84;
        let acdY1 = canvasHeight * 0.82;
        let acdX2 = acdX1;
        let acdY2 = canvasHeight * 0.5;
        this.context.lineWidth = 2;
        this.context.moveTo(acdX1, acdY1);
        this.context.lineTo(acdX2, acdY2);
        this.context.stroke();

        acdX1 = canvasWidth * 0.839; //acdX2;
        acdY1 = acdY2;
        acdX2 = canvasWidth * 0.9;
        acdY2 = acdY1;
        this.context.lineWidth = 2;
        this.context.moveTo(acdX1, acdY1);
        this.context.lineTo(acdX2, acdY2);
        this.context.stroke();

        // cct box:
        this.context.beginPath();
        let cctX = canvasWidth * 0.754;
        let cctY = canvasHeight * 0.072;
        this.context.strokeStyle = isInvalid('ct') ? errorStrokeColor : strokeColor;
        this.context.fillStyle = isInvalid('ct') ? errorFillColor : fillColor;
        this.context.rect(cctX, cctY, boxWidth, boxHeight);
        this.context.fill();
        this.context.stroke();

        let cctX1 = canvasWidth * 0.841;
        let cctY1 = canvasHeight * 0.23;
        let cctX2 = cctX1;
        let cctY2 = canvasHeight * 0.45;
        this.context.lineWidth = 2;
        this.context.moveTo(cctX1, cctY1);
        this.context.lineTo(cctX2, cctY2);
        this.context.stroke();

        //Draw text labels for boxes
        this.context.fillStyle = '#BBBBBB';
        this.context.font = this.labelFont;

        const labelsPositions = {};
        labelsPositions.wwX = wwX + labelOffsetX;
        labelsPositions.wwY = wwY + labelOffsetY;

        // HTML5 Canvas does not have a way to do line breaks in fillText() function
        let txt = this.splitText(i18n.i18next.t('preop_DiagramCanvas_WtW_Line1'));
        this.context.fillText(txt[0], labelsPositions.wwX, labelsPositions.wwY);
        this.context.fillText(txt[1], labelsPositions.wwX, labelsPositions.wwY + 12);
        this.context.fillText(txt[2], labelsPositions.wwX, labelsPositions.wwY + 24);

        labelsPositions.cctX = cctX + labelOffsetX;
        labelsPositions.cctY = cctY + labelOffsetY;
        txt = this.splitText(i18n.i18next.t('preop_DiagramCanvas_CentralCornealThickness_Line1'));
        this.context.fillText(txt[0], labelsPositions.cctX, labelsPositions.cctY);
        this.context.fillText(txt[1], labelsPositions.cctX, labelsPositions.cctY + 12);
        this.context.fillText(txt[2], labelsPositions.cctX, labelsPositions.cctY + 24);

        labelsPositions.acdX = acdX + labelOffsetX;
        labelsPositions.acdY = acdY + labelOffsetY;
        txt = this.splitText(i18n.i18next.t('preop_DiagramCanvas_AnteriorChamberDepth_Line1'));
        this.context.fillText(txt[0], labelsPositions.acdX, labelsPositions.acdY);
        this.context.fillText(txt[1], labelsPositions.acdX, labelsPositions.acdY + 12);
        this.context.fillText(txt[2], labelsPositions.acdX, labelsPositions.acdY + 24);

        return {
            ...labelsPositions,
            wwY: labelsPositions.wwY + 48,
            cctY: labelsPositions.cctY + 48,
            acdY: labelsPositions.acdY + 48,
        };
    }

    /* If the eye side associated with this canvas is currently not being edited, draw an overlay that obscures the image, and includes a button to toggle the active eye. */
    drawInactiveOverlay() {
        this.context.fillStyle = 'smokewhite';
        this.context.globalAlpha = 0.5;
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    hasValue(axis) {
        return !((typeof axis === 'string' && axis.length == 0) || axis === null);
    }
}
