export const SortCategories = Object.freeze({
    ACTIVITY: 'activity',
    SURGEON: 'surgeon_name',
    PATIENT: 'patient_name',
    PATIENT_REF: 'ocos_patient_ref',
});

export const Genders = Object.freeze({
    MALE: 'M',
    FEMALE: 'F',
    OTHER: 'O',
});
