<template>
    <div>
        <ErrorToast
            v-for="field in biometryFields"
            :title="toastTitle"
            :key="biometryToastKey(field)"
            :visible="biometryToastVisible(field)"
            :ident="side + '-' + field.key + '-range'"
        >
            {{ errorDescription(field) }}
        </ErrorToast>

        <ErrorToast
            v-for="field in refractionFields"
            :title="toastTitle"
            :key="refractionToastKey(field)"
            :visible="refractionToastVisible(field)"
            :ident="side + '-' + refractionType + field.key + '-range'"
        >
            {{ errorDescription(field) }}
        </ErrorToast>

        <!-- This toast is for display custom or manual validation messages that are out side form validation -->
        <ErrorToast
            v-for="error in customToastErrors"
            :key="error.key"
            :title="toastTitle"
            :visible="error.visible"
            :ident="error.key"
        >
            {{ error.description }}
        </ErrorToast>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {get} from 'lodash';
import {ZoneCodes} from '@/constants/zone';
import {RefractionTypes} from '@/store/modules/preopdata';
import {formatNumber} from '@/utilities/formatters';

const refractionTypes = Object.values(RefractionTypes);

export default {
    props: {
        side: String,
        validation: Object,
        customToastErrors: Array,
        refractionType: [String, Number],
    },
    computed: {
        ...mapGetters('preopdata', ['validationRanges']),
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('zone', ['currentZone']),

        toastTitle() {
            return this.t('preop_ErrorToast', {side: this.side});
        },
        biometryFields() {
            return [
                {
                    key: 'k1',
                    label: this.t('preop_BiometryFields_k1'),
                    unit: '\u00A0D',
                    decimalPlaces: 2,
                },
                {key: 'kAxis', label: this.t('preop_BiometryFields_kAxis'), unit: '\xB0'},
                {
                    key: 'acd',
                    label: this.t('preop_BiometryFields_acd'),
                    unit: '\u00A0mm',
                    decimalPlaces: 2,
                },
                {key: 'ct', label: this.t('preop_BiometryFields_ct'), unit: '\u00A0µm'},
                {
                    key: 'ww',
                    label: this.t('preop_BiometryFields_ww'),
                    unit: '\u00A0mm',
                    decimalPlaces: 2,
                },
                {
                    key: 'k2',
                    label: this.t('preop_BiometryFields_k2'),
                    unit: '\u00A0D',
                    decimalPlaces: 2,
                },
                {key: 'k2Axis', label: this.t('preop_BiometryFields_k2Axis'), unit: '\xB0'},
            ];
        },
        refractionFields() {
            return [
                {
                    key: 'sphere',
                    label: this.t('preop_RefractionFields_sphere'),
                    unit: '\u00A0D',
                    sign: '+',
                    decimalPlaces: 2,
                    refractionTypes,
                },
                {
                    key: 'cylinder',
                    label: this.t('preop_RefractionFields_cylinder'),
                    unit: '\u00A0D',
                    sign: '+',
                    decimalPlaces: 2,
                    refractionTypes,
                },
                {
                    key: 'axis',
                    label: this.t('preop_RefractionFields_axis'),
                    unit: '\xB0',
                    refractionTypes,
                },
                {
                    key: 'bvd',
                    label: this.t('preop_RefractionFields_bvd'),
                    unit: '\u00A0mm',
                    decimalPlaces: 2,
                    refractionTypes,
                },
                {
                    key: 'cl',
                    label: this.t('preop_SphereRangeText'),
                    unit: '\u00A0D',
                    decimalPlaces: 2,
                    sign: this.currentZone == ZoneCodes.OUS ? '+' : '',
                    refractionTypes: [RefractionTypes.CL],
                },
            ];
        },
    },
    methods: {
        get,
        errorDescription(field) {
            const {decimalSeparator} = this.currentUser;
            const {key, label, unit, sign = '', decimalPlaces = 0} = field;
            const keyMapped = key === 'cl' ? 'clsphere' : key.toLowerCase();
            const {min, max} = this.validationRanges[keyMapped];
            const minD = formatNumber(min, decimalPlaces, decimalSeparator);
            const maxD = formatNumber(max, decimalPlaces, decimalSeparator);
            const validRange = `${minD}${unit} ... ${max > 0 ? sign : ''}${maxD}${unit}`;
            return this.t('preop_ErrorDescription', {
                inputName: label,
                validRange: validRange,
            });
        },
        refractionToastVisible(field) {
            return (
                !get(
                    this.validation,
                    `dataSet.refractionInfo[${this.refractionType}][${field.key}].range`
                ) && field.refractionTypes.includes(this.refractionType)
            );
        },
        refractionToastKey(field) {
            return `${this.side}-${this.refractionType}-${field.key}-${this.refractionToastVisible(
                field
            )}`;
        },
        biometryToastVisible(field) {
            return !this.validation.dataSet[field.key].range;
        },
        biometryToastKey(field) {
            return `${this.side}-${field.key}-${this.biometryToastVisible(field)}`;
        },
    },
};
</script>
